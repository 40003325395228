import { ReactComponent as TaskSvg } from 'assets/task.svg';

import { AuthLayout } from 'layouts/AuthLayout';
import { NavyLink } from 'components/Shared/NavyLink';

export function LandingPage() { 
    return (
        <div>
        <AuthLayout 
            form={
                <div>
                    <h1 className="mt-6 text-2xl sm:text-3xl font-bold text-navy-400 select-none">Πλατφόρμα GreekQA</h1>
                    <div className="mt-8 space-y-4 text-sm sm:text-base">
                        <p>
                            H <b> Πλατφόρμα Συλλογικής Επισημείωσης Δεδομένων GreekQA</b> συνεισφέρει στην δημιουργία του <i>πρώτου ελληνικού συνόλου δεδομένων ερωτήσεων και απαντήσεων</i>. Το σύνολο δεδομένων αυτό θα αποτελείται από ερωτήσεις και απαντήσεις σε άρθρα της ελληνικής Wikipedia.
                            {/* H <b> Πλατφόρμα Συλλογικής Επισημείωσης Δεδομένων GreekQA</b>, θέλουμε να δημιουργήσουμε το πρώτο Ελληνικό Σύνολο Δεδομένων Ερωτήσεων και Απαντήσεων. */}
                        </p>
                        <p>
                            Τα τελευταία χρόνια, o κλάδος της <i>Επεξεργασίας Φυσικής Γλώσσας</i> έχει σημειώσει αξιοσημείωτη πρόοδο με την εντυπωσιακή εξέλιξη ευέλικτων γλωσσικών μοντέλων. Η <i>Μηχανική Αναγνωστική Κατανόηση (Machine Reading Comprehension)</i>, δηλαδή η ανάγνωση ενός αποσπάσματος κειμένου και στη συνέχεια την απάντηση σχετικών ερωτήσεων από μια μηχανή, είναι ένας από τους τομείς που επωφελήθηκαν από αυτές τις εξελίξεις.
                        </p>
                        <p>
                            Ωστόσο, η πλειονότητα των επιστημονικών εργασιών αυτού του τομέα αφορούν την αγγλική γλώσσα, καθώς τα διαθέσιμα δεδομένα που διατίθονται σε άλλες γλώσσες, όπως τα ελληνικά, είναι περιορισμένα.
                        </p>
                        <p>
                            Η συγκεκριμένη πλατφόρμα δημιουργήθηκε από τον <NavyLink a="true" target="_blank" className="font-bold" href="https://siatras.com">Στάθη Σιάτρα</NavyLink> στα πλαίσια της πτυχιακής του για την <NavyLink a="true" target="_blank" className="font-bold" href="http://ai.di.uoa.gr/">Ομάδα Τεχνητής Νοημοσύνης</NavyLink> του Τμήματος Πληροφορικής και Τηλεπικοινωνιών, Εθνικού και Καποδιστριακού Πανεπιστημίου Αθηνών με επιβλέποντες τον <NavyLink a="true" target="_blank" className="font-bold" href="http://cgi.di.uoa.gr/~koubarak/">Καθηγητή Μανόλη Κουμπαράκη</NavyLink> και την <NavyLink a="true" className="font-bold" target="_blank" href="https://cgi.di.uoa.gr/~dpantazi/">Υποψήφια Διδάκτωρ Δέσποινα-Αθανασία Πανταζή</NavyLink>.
                        </p>
                        <p>    
                            Με την δική σας συνεισφορά, το σύνολο δεδομένων αυτό θα μπορέσει να αναπτυχθεί και να αποτελέσει ακρογωνιαίο λίθο στην περαιτέρω ανάπτυξη και δοκιμή ελληνικών και πολυγλωσσικών μοντέλων.
                        </p>
                    </div>
                    <div className="relative my-4">
                        <div className="absolute inset-0 flex items-center">
                            <div className="w-full border-t border-navy-300" />
                        </div>
                        <div className="relative flex justify-center">
                            <span className="px-2 bg-white text-navy-900 select-none">Ξεκινήστε να συνεισφέρετε</span>
                        </div>
                    </div>
                    <div className="relative flex justify-center">
                        <NavyLink className="mx-auto text-center" to="/login">Είσοδος στην εφαρμογή</NavyLink>
                        <NavyLink className="mx-auto text-center" to="/signup">Εγγραφή στην εφαρμογή</NavyLink>
                    </div>
                </div>
            }
            illustration={ <TaskSvg /> }
        />
    </div>
    );
}   