import { Link } from 'react-router-dom';
import { NavyLink } from 'components/Shared/NavyLink';
import { Button } from 'components/Shared/Button';

export function GetStarted() {
    return (
        <div>
            <div className="mt-6 mx-auto text-navy-900">
                Πρωτού ξεκινήσετε την συνεισφορά σας στο GreekQA, σας συστήνουμε να διαβάσετε τις <NavyLink className="font-medium" to="/guidelines">Οδηγίες Χρήσης</NavyLink>.
            </div>
            <div className="my-8 space-x-4 flex">
                <Link to="/contribute-questions-answers" className="items-center justify-center px-4 py-2 text-white font-medium rounded-lg transition duration-200 ease-in-out transform focus:outline-none bg-navy-200 pointer-events-none cursor-not-allowed">Συνεισφορά Ερωτήσεων και Απαντήσεων</Link>
                <Link to="/contribute-answers" className="px-4 py-2 bg-navy-400 text-white font-medium rounded-lg transition duration-200 ease-in-out hover:bg-navy-600">Συνεισφορά Επιπρόσθετων Απαντήσεων</Link>
            </div>
        </div>
    );
}
