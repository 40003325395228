import { useReducer, useEffect, useState } from 'react';
import { db } from 'firebase/config';
import { collectionGroup, query, where, limit, getDocs, updateDoc, doc, getDoc, runTransaction } from 'firebase/firestore';

const firestoreReducer = (state, action) => {
  switch (action.type) {
    case 'IS_PENDING':
      return { isPending: true, document: null, success: false, error: null }
    case 'READ_DOCUMENT':
      return { isPending: false, document: action.payload, success: true, error: null }
    case 'ADDED_DOCUMENT':
      return { isPending: false, document: action.payload, success: true, error: null }
    case "UPDATED_DOCUMENT":
      return { isPending: false, document: action.payload, success: true,  error: null }
    case 'ERROR':
      return { isPending: false, document: null, success: false, error: action.payload }
    default:
      return state
  }
}

export const useFirestoreCompletedParagraphs = () => {
  const [response, dispatch] = useReducer(firestoreReducer, {
    document: null,
    isPending: false,
    error: null,
    success: null,
  })
  const [isCancelled, setIsCancelled] = useState(false);

  const paragraphsRef = collectionGroup(db, 'paragraphs');

  const dispatchIfNotCancelled = (action) => {
    if (!isCancelled) {
      dispatch(action)
    }
  }

  const getDocument = async (user_uid) => { // DONE
    dispatchIfNotCancelled({ type: 'IS_PENDING' });

    try {
      var title;
      var paragraph;
      var answer_id = 0;

      var q = query(paragraphsRef, where("completed", '==', true), where("completed_answer_1", '==', "false"), where("assigned_user_answer_1", '==', user_uid), limit(1));
      var querySnapshot = await getDocs(q);
      answer_id = 1;
  
      if (querySnapshot.empty) {
        q = query(paragraphsRef, where("completed", '==', true), where("completed_answer_2", '==', "false"), where("assigned_user_answer_2", '==', user_uid), limit(1));
        querySnapshot = await getDocs(q);
        answer_id = 2;

        if (querySnapshot.empty) {
          // q = query(paragraphsRef, where("completed", '==', true), where("assigned_user_answer_1", '!=', ""), where("assigned_user_answer_1", '!=', user_uid), where("assigned_user_answer_2", '==', ""), limit(1));
          q = query(paragraphsRef, where("completed", '==', true), where("assigned_user_answer_1", 'not-in', [user_uid, ""]), where("assigned_user_answer_2", '==', ""), limit(1));
          querySnapshot = await getDocs(q);
          answer_id = 2;

          if (querySnapshot.empty) {
            q = query(paragraphsRef, where("completed", '==', true), where("assigned_user_answer_1", '==', ""), limit(1));
            querySnapshot = await getDocs(q);
            answer_id = 1;
          }
        }
      }

      querySnapshot.forEach((doc) => {
        title = doc.ref.parent.parent.id;
        paragraph = doc.data();
          if (answer_id === 1 && paragraph.assigned_user_answer_1 === "") {
            updateDoc(doc.ref, { assigned_user_answer_1: user_uid });
          }
          if (answer_id === 2 && paragraph.assigned_user_answer_2 === "") {
            updateDoc(doc.ref, { assigned_user_answer_2: user_uid });
          }
      });

      dispatchIfNotCancelled({ type: 'READ_DOCUMENT', payload: { title, paragraph, answer_id } });
    }
    catch (err) {
      console.log(err.message);
      dispatchIfNotCancelled({ type: 'ERROR', payload: err.message });
    }
  }

  const updateAnswers = async (document, user_uid) => {
    dispatchIfNotCancelled({ type: 'IS_PENDING' });
  
    try {
      const docRef = doc(db, 'articles', document.title, 'paragraphs', document.paragraph.uid);

      await runTransaction(db, async (transaction) => {
        const docSnap = await transaction.get(docRef);
        const data = docSnap.data();
        for (let i=0; i<document.paragraph.qas.length; i++) {
          const newAnswer = document.paragraph.qas[i].answers[0];
          data.qas[i].answers.push(newAnswer);
        }
        data[`completed_answer_${document.answer_id}`] = true;
        transaction.update(docRef, data);
      });
  

      // Update User Statistics
      const userSnap = await getDoc(doc(db, 'users', user_uid));
      var userData = userSnap.data();

      var newUserData = {
        numAdditionalAnswers : userData.numAdditionalAnswers + document.paragraph.qas.length,
        numAdditionalParagraphs : userData.numAdditionalParagraphs + 1,
      }

      await updateDoc(doc(db, 'users', user_uid), newUserData);

      // Update General Statistics
      const statisticsSnap = await getDoc(doc(db, 'statistics', 'greekqa'));
      var statisticsData = statisticsSnap.data();

      var newStatisticsData = {
        "numAdditionalAnswers": statisticsData.numAdditionalAnswers += document.paragraph.qas.length,
        "numAdditionalParagraphs": statisticsData.numAdditionalParagraphs += 1
      }

      await updateDoc(doc(db, 'statistics', 'greekqa'), newStatisticsData);
      dispatchIfNotCancelled({ type: 'UPDATED_DOCUMENT', payload: { uid: document.paragraph.uid } });
    }
    catch (err) {
      dispatchIfNotCancelled({ type: 'ERROR', payload: err.message });
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { getDocument, updateAnswers, response }

}