import { NavyLink } from 'components/Shared/NavyLink';

export const GuidelineItems = [
    {
        question: "Τι είναι το GreekQA (Greek Question Answering Dataset);",
        answer: <div className="space-y-4">
                    <p>
                        Στην παρούσα <b>Πλατφόρμα Συλλογικής Επισημείωσης Δεδομένων GreekQA</b>, θέλουμε να δημιουργήσουμε το <b>πρώτου ελληνικό σύνολο δεδομένων ερωτήσεων και απαντήσεων</b>. Το σύνολο δεδομένων αυτό θα αποτελείται από ερωτήσεις και απαντήσεις σε <b>άρθρα της ελληνικής Wikipedia</b>.
                    </p>
                    <p>
                        Τα τελευταία χρόνια, o κλάδος της Επεξεργασίας Φυσικής Γλώσσας έχει σημειώσει αξιοσημείωτη πρόοδο με την εντυπωσιακή εξέλιξη ευέλικτων γλωσσικών μοντέλων. Η Μηχανική Αναγνωστική Κατανόηση (Machine Reading Comprehension), δηλαδή η ανάγνωση ενός αποσπάσματος κειμένου και στη συνέχεια την απάντηση σχετικών ερωτήσεων από μια μηχανή, είναι ένας από τους τομείς που επωφελήθηκαν από αυτές τις εξελίξεις.
                    </p>
                    <p>
                        Ωστόσο, η <b>πλειονότητα</b> των επιστημονικών εργασιών αυτού του τομέα <b>αφορούν την αγγλική γλώσσα</b>, καθώς τα <b>διαθέσιμα δεδομένα</b> που διατίθονται <b>σε άλλες γλώσσες</b>, όπως <b>τα ελληνικά</b>, είναι <b>περιορισμένα</b>.
                    </p>
                    <p>
                        Η συγκεκριμένη πλατφόρμα δημιουργήθηκε από τον <NavyLink a="true" target="_blank" className="font-bold" href="https://siatras.com">Στάθη Σιάτρα</NavyLink> στα πλαίσια της πτυχιακής του για την <NavyLink a="true" target="_blank" className="font-bold" href="http://ai.di.uoa.gr/">Ομάδα Τεχνητής Νοημοσύνης</NavyLink> του Τμήματος Πληροφορικής και Τηλεπικοινωνιών, Εθνικού και Καποδιστριακού Πανεπιστημίου Αθηνών με επιβλέποντες τον <NavyLink a="true" target="_blank" className="font-bold" href="http://cgi.di.uoa.gr/~koubarak/">Καθηγητή Μανόλη Κουμπαράκη</NavyLink> και την <NavyLink a="true" className="font-bold" target="_blank" href="https://cgi.di.uoa.gr/~dpantazi/">Υποψήφια Διδάκτωρ Δέσποινα-Αθανασία Πανταζή</NavyLink>.
                    </p>
                </div>,
        open: "true"
    },
    {
        question: "Γιατί να συνεισφέρω;",
        answer: <div className="space-y-4">
                    <p>
                        Για να επιτευχθεί ο στόχος, είναι <b>καλοδεχούμενη οποιαδήποτε συμμετοχή και βοήθεια</b> στην συλλογή ερωτήσεων και δεδομένων. 
                    </p>
                    <p>    
                        <b>Με την δική σας συνεισφορά</b>, το <b>GreekQA</b> θα μπορέσει να αναπτυχθεί και να αποτελέσει <b>ακρογωνιαίο λίθο</b> στην περαιτέρω <b>ανάπτυξη</b> και <b>δοκιμή ελληνικών</b> και <b>πολυγλωσσικών μοντέλων Αναγνωστικής Κατανόησης</b>.
                    </p>
                </div>,
        open: "true"
    },
    // {
    //     question: "Τι είδη ερωτήσεων αναζητούμε;",
    //     answer: <div className="space-y-4">
    //                 <p>
    //                      Γενικά θέλουμε οι ερωτήσεις <b>να μη χρησιμοποιούν ακριβώς τις ίδιες λέξεις/φράσεις που εμφανίζονται στο κείμενο, αλλά να απαιτούν μια λογική σκέψη (συλλογισμό)</b>.
    //                 </p>
    //                 <p>
    //                      Στα παρακάτω παραδείγματα των ειδών ερωτήσεων θα δούμε:
    //                      <ul className="pl-4 list-disc">
    //                         <li>
    //                             τις ερωτήσεις,
    //                         </li>
    //                         <li>
    //                             τις <b className="text-navy-500">λέξεις που συνδέεουν νοητά την ερώτηση με το κείμενο</b>,
    //                         </li>
    //                         <li>
    //                             τις <b><u>απαντήσεις</u></b>.
    //                         </li>
    //                      </ul>
    //                 </p>
    //                 <p>
    //                     <b>Προσοχή στις απαντήσεις των παραδειγμάτων</b>, δεν θέλουμε όλη την πρόταση, αλλά <b>το μικρότερο δυνατό μέρος του κειμένου (φράση ή λέξη)</b> που απαντά στην εκάστοτε ερώτηση.
    //                 </p>
    //                 <p>
    //                     <ul className="pl-4 list-decimal space-y-4">
    //                         <li><b>Λεκτική παραλλαγή (συνωνυμία)</b>: Οι κύριες αντιστοιχίες μεταξύ της ερώτησης και της απάντησης είναι συνώνυμα.
    //                             <br/>
    //                             <i>Παράδειγμα:</i>
    //                             <ul className="pl-8 list-disc space-y-2">
    //                                 <li><b>Ερώτηση:<br/></b> Πως <b className="text-navy-500">ονομάζεται</b> μερικές φορές ο κύκλος Rankine; </li>
    //                                 <li><b>Πρόταση κειμένου (<u>η απάντηση σε υπογράμμιση</u>)</b>:<br/>Ο κύκλος Rankine μερικές φορές <b className="text-navy-500">αναφέρεται</b> ως <b><u>πρακτικός κύκλος Carnot</u></b>.</li>
    //                             </ul>
    //                         </li>
    //                         <li><b>Λεκτική παραλλαγή (παγκόσμια γνώση)</b>: Οι κύριες αντιστοιχίες μεταξύ της ερώτησης και της απάντησης απαιτούν παγκόσμια γνώση.
    //                             <br/>
    //                             <i>Παράδειγμα:</i>
    //                             <ul className="pl-8 list-disc space-y-2">
    //                                 <li><b>Ερώτηση:<br/></b> Ποια <b className="text-navy-500">κυβερνητικά όργανα</b> έχουν δικαίωμα βέτο;</li>
    //                                 <li><b>Πρόταση κειμένου (<u>η απάντηση σε υπογράμμιση</u>):<br/></b> <b className="text-navy-500"><u>Το Ευρωπαϊκό Κοινοβούλιο και το Συμβούλιο της Ευρωπαϊκής Ένωσης</u></b> έχουν δικαίωμα τροποποίησης και βέτο κατά τη νομοθετική διαδικασία.</li>
    //                             </ul>
    //                         </li>
    //                         <li><b>Συντακτική παραλλαγή</b>: Μετά την παράφραση της ερώτησης σε δηλωτική μορφή, η εξάρτηση δομής δεν ταιριάζει με αυτήν της απάντησης ακόμη και μετά από τοπικές τροποποιήσεις.
    //                             <br/>
    //                             <i>Παράδειγμα:</i>
    //                             <ul className="pl-8 list-disc space-y-2">
    //                                 <li><b>Ερώτηση:<br/></b> Ποιος ερευνητής του Shakespeare είναι αυτή τη στιγμή στο <b className="text-navy-500">Διδακτικό Ερευνητικό Προσωπικό</b>;</li>
    //                                 <li><b>Πρόταση κειμένου (<u>η απάντηση σε υπογράμμιση</u>):<br/></b> To <b className="text-navy-500">Διδακτικό Ερευνητικό Προσωπικό</b> περιλαμβάνει τον ανθρωπολόγο Marshall Sahlins, ..., τον μελετητή του Shakespeare <b><u>David Bevington</u></b>.</li>
    //                             </ul>
    //                         </li>
    //                         <li><b>Συλλογισμός πολλαπλών προτάσεων</b>: Υπάρχει αναφορά ή απαιτείται συγχώνευση πολλαπλών προτάσεων.
    //                             <br/>
    //                             <i>Παράδειγμα:</i>
    //                             <ul className="pl-8 list-disc space-y-2">
    //                                 <li><b>Ερώτηση:<br/></b> Τι συλλογή κατέχουν <b className="text-navy-500">η γκαλερί V&A Theatre & Performance</b>;</li>
    //                                 <li><b>Πρόταση κειμένου (<u>η απάντηση σε υπογράμμιση</u>):<br/></b> <b className="text-navy-500">Η γκαλερί V&A Theatre & Performance</b> άνοιξαν τον Μάρτιο του 2009. ... <b className="text-navy-500">Αυτή η γκαλερί</b> διατηρεί τη μεγαλύτερη εθνική συλλογή του Ηνωμένου Βασιλείου από <b><u>εκθέματα σχετικά με ζωντανές εμφανίσεις</u></b>.</li>
    //                             </ul>
    //                         </li>
    //                     </ul>
    //                 </p>
    //             </div>,
    //     open: ""
    // },
    {
        question: "Τι είδη απαντήσεων αναζητούμε;",
        answer: <div className="space-y-4">
                    <p>
                    Η απάντηση πρέπει να είναι <b>το μικρότερο δυνατό μέρος του κειμένου (φράση ή λέξη) που απαντά στην εκάστοτε ερώτηση</b> και <b>όχι ολόκληρη πρόταση</b>.
                    </p>
                    <p>
                        Γενικά, τα είδη των απαντήσεων που αναζητούμε είναι:
                        <ul className="pl-4 list-disc">
                            <li><b>Ημερομηνία</b>: 19 Οκτωβρίου 1512</li>
                            <li><b>Αριθμός</b>: 12</li>
                            <li><b>Άτομο</b>: Μανόλης Κουμπαράκης</li>
                            <li><b>Τοποθεσία</b>: Αθήνα</li>
                            <li><b>Οντότητα</b>: Ευρωπαϊκή Ένωση</li>
                            <li><b>Φράση ουσιαστικού</b>: καταστροφή ιδιοκτησίας</li>
                            <li><b>Φράση επιθέτου</b>: δεύτερο μεγαλύτερο</li>
                            <li><b>Φράση ρήματος</b>: επέστρεψε στην Γη</li>
                            <li><b>Πρόταση/Υποπρόταση</b>: για να αποφευχθεί ο πόλεμος</li>
                            <li><b>Άλλα</b>: προσεκτικά</li>
                        </ul>
                    </p>
                </div>,
            open: ""
    },
    {
        question: "Πρέπει οι απαντήσεις να είναι αναγκαστικά μέρος του κειμένου;",
        answer: <div className="space-y-4">
                    <p>
                        <b>Ναι.</b> Ο χρήστης γράφει την ερώτηση και στην συνέχεια <b>μαρκάρει το μικρότερο δυνατό τμήμα του κειμένου που απαντά στην εκάστοτε ερώτηση</b>.
                    </p>
                </div>,
        open: ""
    },
    // {
    //     question: 'Πόσο δύσκολες πρέπει να είναι οι ερωτήσεις;',
    //     answer: <div className="space-y-4">
    //                 <p>
    //                     Οι ερωτήσεις που αναζητούμε πρέπει να είναι <b>δύσκολες στο να απαντηθούν</b>, ώστε να απαιτούν κάποιο <b>συλλογισμό</b>. Επίσης, πρέπει να είναι <b>απλές και ακριβείς ως προς την διατύπωση</b>.
    //                 </p>
    //                 <p>
    //                     Παραδείγματα τέτοιων ερωτήσεων βρίσκονται στην καρτέλα <b><i>"Tι είδη ερωτήσεων αναζητούμε;"</i></b>.
    //                 </p>
    //             </div>,
    //     open: ""
    // },
    // {
    //     question: "Πόσο σημαντική είναι η ποικιλομορφία στις ερωτήσεις/απαντήσεις;",
    //     answer: <div className="space-y-4">
    //                 <p>
    //                     <b>Η ποικιλομορφία</b> των ερωτήσεων/απαντήσεων αποτελεί <b>σημαντικό παράγοντα</b> για την άρτια ανάπτυξη του dataset.
    //                 </p>
    //                 <p>
    //                     Συνεπώς, σας παροτρύνουμε να συνεισφέρετε όσο το δυνατόν ερωτήσεις και απαντήσεις <b>διαφορετικών ειδών</b>.
    //                 </p>
    //             </div>,
    //     open: ""
    // },
    {
        question: "Πως μπορώ να συνεισφέρω;",
        answer: <div className="space-y-4">
                    <p>
                        Μπορείτε να ξεκινήσετε να συνεισφέρετε άμεσα στην καρτέλα <NavyLink className="font-bold" to="/get-started">Ξεκινήστε</NavyLink>.
                    </p>
                </div>,
        open: ""
    },
    {
        question: "Έχω κι άλλες απορίες. Πού μπορώ να απευθυνθώ;",
        answer: <div className="space-y-4">
                    <p>
                        Για <b>οποιαδήποτε απορία</b>, μη διστάσετε να επικοινωνήσετε μαζί μας στη διεύθυνση <NavyLink a="true" className="font-bold" href="mailto:greekqa.di.uoa@gmail.com">greekqa.di.uoa@gmail.com</NavyLink> ή στο piazza του εκάστοτε μαθήματος.
                    </p>
                </div>,
        open: ""
    }
]