import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';

import { useAuthContext } from 'hooks/useAuthContext';
import { useFirestoreCompletedParagraphs } from 'hooks/useFirestoreCompletedParagraphs';

import { NavyLink } from 'components/Shared/NavyLink';
import { Input } from 'components/Shared/Input';
import { Button } from 'components/Shared/Button';

export function ContributeAnswers() { 
    const { user } = useAuthContext();
    const { getDocument, updateAnswers, response } = useFirestoreCompletedParagraphs();
    useEffect(() => {
        getDocument(user.uid);
    }, []);

    const [ document, setDocument ] = useState(null);
    const [ answeredQuestions, setAnsweredQuestions ] = useState([]);
    const [ unansweredQuestion, setUnansweredQuestion ] = useState(null);
    const [ unansweredQuestionIndex, setUnansweredQuestionIndex] = useState(-1);
    useEffect(() => {
        setDocument(response.document);
        if (response.document) {
            const newDocument = JSON.parse(JSON.stringify(response.document));
            if (newDocument.paragraph) {
                newDocument.paragraph.qas.forEach((question) => {
                question.answers = [];
                });
                setDocument(newDocument);
                
                setAnsweredQuestions(newDocument.paragraph.qas.filter((question) => question.answers.length > 0));
                setUnansweredQuestion(newDocument.paragraph.qas.find((question) => question.answers.length === 0));
                setUnansweredQuestionIndex(newDocument.paragraph.qas.findIndex((question) => question.answers.length === 0));
            }
        }
    }, [response.document]);

    const submitAnswers = async (document, user_uid) => {
        const answer = window.confirm("Είστε σίγουροι ότι θέλετε να καταχωρήσετε όλες τις απαντήσεις;");
        if (answer) {
            await updateAnswers(document, user_uid);
            window.location.reload(false);
            // getDocument(user.uid);
        }
    }

    const popAnswer = (index) => {
        const question = answeredQuestions[index];
        const questionIndex = document.paragraph.qas.findIndex((q) => q.question === question.question);
        const newDocument = JSON.parse(JSON.stringify(document));
        newDocument.paragraph.qas[questionIndex].answers.pop();
        setDocument(newDocument);
      };
      
      useEffect(() => {
        if (document != null) {
            const answeredQuestions = document.paragraph.qas.filter((question) => question.answers.length > 0);
            const unansweredQuestion = document.paragraph.qas.find((question) => question.answers.length === 0);
            const unansweredQuestionIndex = document.paragraph.qas.findIndex((question) => question.answers.length === 0);
            setAnsweredQuestions(answeredQuestions);
            setUnansweredQuestion(unansweredQuestion);
            setUnansweredQuestionIndex(unansweredQuestionIndex);
        }
      }, [document]);

    const pushAnswer = (answer) => {
        document.paragraph.qas[unansweredQuestionIndex].answers.push(answer);

        setAnsweredQuestions(document.paragraph.qas.filter((question) => question.answers.length > 0));
        setUnansweredQuestion(document.paragraph.qas.find((question) => question.answers.length === 0));
        setUnansweredQuestionIndex(document.paragraph.qas.findIndex((question) => question.answers.length === 0));
    };

    const [selectedText, setSelectedText] = useState('');
    const [selectionRange, setSelectionRange] = useState({});

    const { register: registerAnswer, resetField: resetFieldAnswer, handleSubmit: handleSubmitAnswer, setValue: setValueAnswer, formState: { errors: errorsAnswer } } = useForm();
    const onSubmitAnswer = (data, e) => {
        e.preventDefault();
        var newAnswer = {
            answer_start: selectionRange.startOffset,
            text: selectedText
        }
        pushAnswer(newAnswer);
        resetFieldAnswer("answer");
        setSelectedText('');
        setSelectionRange({});
    };

    useEffect(() => {
        setValueAnswer("answer", selectedText);
    }, [selectedText]);    

    return (
        <div className="space-y-5">
            <h2 className="mb-6 text-2xl sm:text-3xl font-medium text-navy-600 select-none">
                Συγγραφή επιπρόσθετων απαντήσεων
            </h2>
            <div className="p-6 rounded-lg shadow-lg">
                <h3 className="mb-6 text-xl">
                    <span className="font-medium text-lg sm:text-xl select-none">Τίτλος Άρθρου:</span> <span className="text-lg sm:text-xl select-none">{document != null ? document.title : 'Φόρτωση...'}</span>
                </h3>
                <p className="text-left text-sm sm:text-base" onMouseUp={() => {setSelectedText(window.getSelection().toString()); setSelectionRange(window.getSelection().getRangeAt(0))}} onContextMenu={() => {setSelectedText(window.getSelection().toString()); setSelectionRange(window.getSelection().getRangeAt(0))}}>
                    <span className="font-medium text-lg sm:text-xl select-none">Παράγραφος:</span><br />
                    {document != null ? document.paragraph.context : 'Φόρτωση...'}
                </p>
            </div>
            <div className="my-6">
                {answeredQuestions != null ? answeredQuestions.map((qa, index) => (
                    <Disclosure key={index} as="div" className="my-2 pt-2">
                    {({ open }) => (
                        <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 font-medium text-left text-navy-900 bg-navy-50 rounded-lg shadow-md hover:bg-navy-100 focus:outline-none focus-visible:ring focus-visible:ring-navy-400 focus-visible:ring-opacity-75">
                            {`Ερώτηση/Aπάντηση ${index+1}`}
                            <ChevronUpIcon
                            className={`${
                                open ? 'transform rotate-180' : ''
                            } w-5 h-5 text-navy-600`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-navy-900 space-y-6 rounded-lg bg-gray-50 shadow-lg">
                            <Input label={`Ερώτηση ${index+1}`} type="text" value={qa.question} disabled readOnly></Input>
                            <Input label={`Απάντηση ${index+1}`} type="text" value={qa.answers[0].text} disabled readOnly></Input>
                            <Button red="true" onClick={()=> popAnswer(index)}>Αφαίρεση Απάντησης</Button>
                        </Disclosure.Panel>
                        </>
                    )}
                    </Disclosure>
                )) : 'Φόρτωση...'}
            </div>
            {unansweredQuestion != null ? (
            <form onSubmit={handleSubmitAnswer(onSubmitAnswer)} className="space-y-4">
                <Input label={`Ερώτηση`} type="text" value={unansweredQuestion.question} disabled readOnly></Input>
                <Input label="Απάντηση" id="answer" name="answer" type="text" placeholder="Μάρκαρετε την απάντηση στην παράγραφο"  readOnly value={selectedText}
                       errors={errorsAnswer.answer} register={registerAnswer("answer", { required: "Παρακαλώ συμπληρώστε μια απάντηση (π.χ. 12.742 χλμ.)"})} 
                />
                <Button green="true" type="submit">Προσθήκη απάντησης</Button>
                {/* <Button green="true" type="submit" disabled={document ? (document.paragraph.qas.length >= 5 ? true: false): false}>Προσθήκη Ερώτησης {document ? (document.paragraph.qas.length >= 5 ? "(Μπορείτε να καταχωρήσετε το πολύ 5 ερωτήσεις/απαντήσεις ανά παράγραφο!)" : ""): ""}</Button> */}
            </form>) : ''}
            <div>
                <b className="text-red-500">Προσοχή: </b>
                <ul className="pl-5 list-disc">
                    <li>Η απάντηση πρέπει να είναι <b className="text-navy-500">το μικρότερο δυνατό μέρος του κειμένου (φράση ή λέξη) που απαντά στην εκάστοτε ερώτηση</b> και <b className="text-navy-500">όχι ολόκληρη πρόταση</b>.</li>
                    <li>Σε κάθε παράγραφο πρέπει να <b className="text-navy-500">συμπληρώσετε όλες τις απαντήσεις</b> στις δοθέντες ερωτήσεις.</li>
                    <li>Πριν καταχωρήσετε τις ερωτήσεις/απαντήσεις σας, παρακαλούμε <b className="text-navy-500">βεβαιωθείτε ότι είναι σωστές</b>, διότι δεν μπορούν να αλλαχθούν στην συνέχεια.</li>
                    <li>Πριν αποχωρήσετε από την σελίδα, παρακαλούμε <b className="text-navy-500">καταχωρήστε τις ερωτήσεις/απαντήσεις σας</b>, διότι δεν αποθηκεύονται προσωρινά.</li>
                    <li>Περισσότερες λεπτομέρειες στις <b className="text-navy-500">οδηγίες χρήσης παρακάτω</b>.</li>
                </ul>
            </div>
            <Button type="submit" onClick={()=> submitAnswers(document, user.uid)} disabled={unansweredQuestion ? true : false}>Καταχώρηση όλων των απαντήσεων</Button>
            <div>
                <NavyLink to="/get-started">Επιστροφή στην εφαρμογή</NavyLink>
            </div>
        </div>
    );
}